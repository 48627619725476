<template>
  <div class="app-container">
    <div class="avatar-wrapper">
      <van-image
        width="60"
        height="60"
        class="avatar"
        round
        :src="dataList.wechat_avatar"
      />
      <div class="nickname">{{ $Base64.decode(dataList.wechat_nickname) }}</div>
    </div>
    <van-cell-group class="block">
      <van-cell title="动态标题" :value="dataList.article_name" />
      <van-cell title="商品名称" :value="dataList.goods_name" />
      <van-cell title="商品价格" :value="'￥'+dataList.sale_price / 100" />
    </van-cell-group>
    <van-cell-group class="block">
      <van-cell
        v-for="(item, index) in dataList.form_item_list"
        :key="index"
        :title="item.name"
        :value="getVal(item.code)"
      />
    </van-cell-group>
    <van-cell-group class="block" v-if="isConfirmClient">
      <div class="btn">
        <van-button type="info" block @click="onSubmit" v-if="dataList.status === 1">确认到店</van-button>
        <van-button type="info" block disabled v-if="dataList.status === 2">已到店</van-button>
      </div>
    </van-cell-group>
    <van-cell-group class="block" v-else>
      <div style="text-align:center">您没有权限核销，谢谢配合！</div>
    </van-cell-group>
  </div>
</template>
<script>
import { Notify } from 'vant'
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      dataList: {},
      client_type: Number(Cookies.get('client_type')),
      isConfirmClient: false
    }
  },
  mounted () {
    this.getDataList()
    // this.client_type = Cookies.get('client_type')
  },
  methods: {
    getVal (code) {
      return this.dataList.form_data[code]
    },
    getDataList () {
      this.$axios.post('/wxc/article/join_confirm_preview', {
        sys_id: Number(this.$route.query.sys_id)
      }).then(res => {
        this.dataList = res.data
        let client_id = Cookies.get('client_id')
        if(res.data.confirm_client_ids.split('|').indexOf(client_id) !== -1){
          this.isConfirmClient = true
        }
      })
    },
    onSubmit () {
      this.$axios.post('/wxc/article/join_confirm', {
        sys_id: Number(this.$route.query.sys_id)
      }).then(res => {
        if (res.code === 200) {
          this.dataList.status = 2
          Notify({ type: 'success', message: res.msg })
        }else{
          Notify({ type: 'danger', message: res.msg })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.avatar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  .nickname {
    padding: 10px;
  }
}
.btn {
  padding: 0 20px;
}
</style>